import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Button, Dropdown, MessageHeader, Message, FormField, Form, Segment, Table, Loader, Dimmer } from 'semantic-ui-react';
import Wave2 from "../../../images/modalWave2.svg";
import { useAuth } from 'react-oidc-context';
import { SpreadingGroundService, SpreadingGroundUploadResult } from '../../../services/SpreadingGroundService';
import Download from '../../../core/DownloadUtil';
import Config from '../../../config/Config';

import './UploadSpreadingGroundModal.scss';
import axios from 'axios';

type UploadSpreadingGroundModalProps = {
    isOpen: boolean;
    onClose: () => void;
    refreshData: () => void;
}

const waterYearOptions = [
    { key: 2019, text: "2019-20", value: 2019 },
    { key: 2020, text: "2020-21", value: 2020 },
    { key: 2021, text: "2021-22", value: 2021 },
    { key: 2022, text: "2022-23", value: 2022 },
    { key: 2023, text: "2023-24", value: 2023 },
    { key: 2024, text: "2024-25", value: 2024 },
    { key: 2025, text: "2025-26", value: 2025 },
    { key: 2026, text: "2026-27", value: 2026 }
]

const currentDate = new Date();

export const UploadSpreadingGroundModal = (props: UploadSpreadingGroundModalProps) => {
    const [file, setFile] = useState<File | null>(null);
    const [results, setResults] = useState<SpreadingGroundUploadResult | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [warning, setWarning] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // Default to the water year that starts in October. If it's before October, use the previous year.
    const [waterYear, setWaterYear] = useState<number>(currentDate.getMonth() < 9 ? currentDate.getFullYear() - 1 : currentDate.getFullYear());
    const auth = useAuth();
    const spreadingGroundsService = useMemo(() => new SpreadingGroundService(auth.user?.access_token), [auth]);

    const handleUpload = async () => {
        if (!file) {
            return;
        }
        setIsLoading(true);
        setError(null);
        if (!file.name.endsWith(".xlsx")) {
            setError("Invalid file type. Please upload an Excel file.");
            return;
        }
        try {
            const res = await spreadingGroundsService.uploadExcel(file, waterYear)
            setResults(res);
            setWarning(res.warningMessage)
            setFile(null);
            setIsLoading(false);
            props.refreshData();
        } catch (err) {
            if (axios.isAxiosError(err)) {
                setError(err.response.data.error ?? "Failed to upload excel, an unknown error occurred");
                setIsLoading(false);
                setFile(null);
                return;
            }
            else {
                setError("Failed to upload excel, an unknown error occurred");
            }
            setIsLoading(false);
        }
    }

    const handleClose = useCallback(() => {
        setFile(null);
        setResults(null);
        setError(null);
        setWarning(null);
        props.onClose();
    }, [props]);

    const downloadFile = useCallback(async () => {
        try {
            await Download(Config.api_endpoint + `SpreadingGrounds/Template`, auth.user?.access_token, "Spreading Grounds Capture Template.xlsx");
        } catch (err) {
            console.error(err);
            setError("Failed to download template");
        }
    }, [auth]);

    return (
        <Modal
            open={props.isOpen}
            className="confirm-delete-modal upload-sg-modal"
            size="small"
            centered={true}
            onClose={handleClose}
        >
            <Modal.Header style={{ marginBottom: "-1.2em" }} >Upload New Monthly Data</Modal.Header>

            <Modal.Content>
                <Message info>
                    <MessageHeader>
                        Directions:
                    </MessageHeader>
                    <p className="upload-sg-modal__dir-msg-body">
                        Use the functionality below to upload spreading ground conservation records for a month in a given water year.
                    </p>
                    <p className="upload-sg-modal__dir-msg-body">
                        Only one month of data can be updated at a time. The month associated with the right-most, nonblank column in the upload template will be updated. After upload, values provided for the selected month will be displayed below for review.
                    </p>
                    <p className="upload-sg-modal__dir-msg-body">
                        <b>Note:</b> Note that uploading monthly data will overwrite event capture data for that month.
                    </p>
                    <div className="upload-sg-modal__btn-container">
                        <Button
                            content="Download Template"
                            onClick={downloadFile}
                            color="blue"
                        />

                    </div>
                </Message>
                <Segment>
                    <Dimmer inverted active={isLoading}>
                        <Loader active={isLoading} />
                    </Dimmer>

                    {/* <Loader active={true} inline='centered' /> */}
                    <Form>

                        <FormField>
                            <label>Select Water Year</label>
                            <Dropdown
                                //   labeled="Select Water Year"
                                placeholder="Select Water Year"
                                fluid
                                selection
                                options={waterYearOptions}
                                value={waterYear}
                                onChange={(_, data) => setWaterYear(data.value as number)}
                            />
                        </FormField>
                        <input
                            type="file"
                            accept=".xls,.xlsx,.csv,.ods"
                            onChange={(e) => setFile(e.target.files![0])}
                            draggable={true}
                            name='file'
                            id='file'
                        />
                        <div className="upload-sg-modal__btn-container" style={{ marginTop: "1em" }}>
                            <Button
                                onClick={handleUpload}
                                color="blue"
                                disabled={!(file && waterYear)}
                            >
                                Upload
                            </Button>
                        </div>
                    </Form>
                </Segment>

                {error && <Message negative>
                    <p>{error}</p>
                </Message>
                }
                {warning && <Message warning>
                    <p>{warning}</p>
                </Message>
                }
                {results && !error && <>
                    <h3>
                        Results:
                    </h3>
                    {results.results.length > 0 &&
                        <Table celled fixed >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width="1">Type</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Water Year</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Spreading Facility</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Captured</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {results.results.map((r, i) => (
                                    <Table.Row key={i}>
                                        <Table.Cell>{r.oldWaterConservedAcft ? "Updated" : "Added"}</Table.Cell>
                                        <Table.Cell>{results.month}/{results.year}</Table.Cell>
                                        <Table.Cell>{r.spreadingGroundName}</Table.Cell>
                                        <Table.Cell>{(r.oldWaterConservedAcft ? r.oldWaterConservedAcft.toFixed(2) + " to " : "") + r.waterConservedAcft.toFixed(2)}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                    {results.results.length === 0 &&
                        <Message>No data updated.</Message>
                    }
                </>}
            </Modal.Content>
            <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div style={{ padding: "1em" }}>
                    <Button color="blue" onClick={handleClose}>Close</Button>
                </div>
            </Modal.Actions>
        </Modal >
    );
};
