import { SpreadingGround } from "../../../models/SpreadingGround";
import { Button, Modal, Table } from "semantic-ui-react";

interface SpreadingGroundValueModalProps {
    isOpen: boolean;
    onClose: () => void;
    spreadingGround: SpreadingGround | null;
}

const months = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const years = [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026];

const SpreadingGroundCaptureModal = (props: SpreadingGroundValueModalProps) => {

    return (
        <Modal
            open={props.isOpen}
            className="spreading-ground-capture-modal"
            size="large"
            centered={true}
        >
                <Modal.Header style={{ marginBottom: "-20px" }}>{props.spreadingGround?.name}</Modal.Header>
                <Modal.Content>
                    {props.spreadingGround &&
                    <>
                        {/* table of each year and month and the spreading grounds values */}
                        <Table>
                            <Table.Header>
                                    <Table.HeaderCell>Month</Table.HeaderCell>
                                    {years.map((year) => {
                                        return (
                                            <Table.HeaderCell key={year}>{year}</Table.HeaderCell>
                                        );
                                    })}
                            </Table.Header>
                            <Table.Body>
                                {months.map((month) =>
                                    <Table.Row key={month} >
                                        <Table.Cell>{Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(2000, month-1))}</Table.Cell>

                                        {years.map((year) =>
                                            <Table.Cell key={year}>
                                                {props.spreadingGround.spreadingGroundCapture
                                                    .find(x => !x.spreadingGroundCaptureTimeFrame.isCustom
                                                        && x.active
                                                        && new Date(x.spreadingGroundCaptureTimeFrame.startDate).getFullYear() === year
                                                        && new Date(x.spreadingGroundCaptureTimeFrame.startDate).getMonth() === month - 1)?.waterConservedAcft ?? ""}
                                            </Table.Cell>
                                        )}
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                        {props.spreadingGround.spreadingGroundCapture.some(x => x.spreadingGroundCaptureTimeFrame.isCustom && x.active) &&
                            <>
                                <h3>Custom Captures</h3>
                                <div>
                                    {props.spreadingGround.spreadingGroundCapture
                                        .filter(x => x.spreadingGroundCaptureTimeFrame.isCustom && x.active)
                                        .map((capture) => {
                                            return (
                                                <div key={capture.id}>
                                                    <h4>{new Date(capture.spreadingGroundCaptureTimeFrame.startDate).toLocaleDateString() + " - " + new Date(capture.spreadingGroundCaptureTimeFrame.endDate).toLocaleDateString()}</h4>
                                                    <p>{capture.waterConservedAcft}</p>
                                                </div>
                                            );
                                        })}
                                </div>
                            </>
                        }
                    </>}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={props.onClose}>Close</Button>
                </Modal.Actions>
            </Modal>
    );
};

export default SpreadingGroundCaptureModal;