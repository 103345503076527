import { useState, useMemo, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { Button, Dimmer, Loader, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";

import { SpreadingGroundService } from "../../../services/SpreadingGroundService";
import { SpreadingGround } from "../../../models/SpreadingGround";
import LookupFactory from "../../../lookups/LookupFactory";
import SGUITable from "./SGUITable";
import SGFormModal from "../../modals/SpreadingGround/SGFormModal";
import DeleteSGModal from "../../modals/SpreadingGround/DeleteSGModal";
import { UploadSpreadingGroundModal } from "../../modals/SpreadingGround/UploadSpreadingGroundModal";
import { CustomSpreadingGroundModal } from "../../modals/SpreadingGround/CustomSpreadingGroundModal";
import SpreadingGroundCaptureModal from "../../modals/SpreadingGround/SpreadingGroundCaptureModal";

interface SpreadingGroundsTabProps {
    lookups: LookupFactory;
}

const SpreadingGroundsTab = (props: SpreadingGroundsTabProps) => {

    const auth = useAuth();
    const [sgList, setSGList] = useState<SpreadingGround[]>([]);
    const [showSGModal, setShowSGModal] = useState<boolean>(false);
    const [showDeleteSGModal, setShowDeleteSGModal] = useState<boolean>(false);
    const [selectedSGRowValues, setSelectedSGRowValues] = useState<SpreadingGround | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const spreadingGroundsService = useMemo(() => new SpreadingGroundService(auth.user?.access_token), [auth]);
    const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
    const [customModalOpen, setCustomModalOpen] = useState<boolean>(false);
    const [captureModalOpen, setCaptureModalOpen] = useState<boolean>(false);

    const handleGetSGs = useCallback((): void => {
        setIsLoading(true);
        spreadingGroundsService.getAll().then((sgList) => {
            setSGList(sgList);
        })
            .catch((e) => {
                console.error('error getting Spreading Ground', e)
            });
        setIsLoading(false);
    }, [spreadingGroundsService]);

    useEffect(() => {
        if (!sgList.length) {
            handleGetSGs();
        }
    }, [handleGetSGs, sgList.length])

    const handleCreateSGModal = (): void => {
        setShowSGModal(true);
    };

    const handleCreateSG = async (sgValues: SpreadingGround): Promise<void> => {
        setIsLoading(true);
        try {
            await spreadingGroundsService.addNew(sgValues);
            handleGetSGs();
            toast.info('Created Spreading Ground Successfully', { containerId: "manage-project-data-toast-container", autoClose: 5000, });
        } catch (err) {
            console.error("Failed to create Spreading Ground property ", err);
            toast.error('Failed to create Spreading Ground ', { containerId: "manage-project-data-toast-container", autoClose: 5000, });
        }
        setIsLoading(false);
    };

    const handleEditSGModal = (sgValues: SpreadingGround): void => {
        setSelectedSGRowValues(sgValues);
        setShowSGModal(true);
    };

    const handleEditSG = async (sgValues: SpreadingGround): Promise<void> => {
        setIsLoading(true);
        try {
            await spreadingGroundsService.update(sgValues);
            handleGetSGs();
            toast.info('Edited Spreading Ground Property Successfully', { containerId: "manage-project-data-toast-container", autoClose: 5000, });

        } catch (err) {
            console.error("Failed to edit Spreading Ground", err);
            toast.error('Failed to edit Spreading Grounds ', { containerId: "manage-project-data-toast-container", autoClose: 5000, });
        }
        setIsLoading(false);
    };

    const resetSelectedSGRowValues = (): void => {
        setSelectedSGRowValues(null);
    }

    const handleDeleteSGModal = (sgValues: SpreadingGround): void => {
        setSelectedSGRowValues(sgValues);
        setShowDeleteSGModal(true);
    };

    const handleOpenSGCaptureModal = (sgValues: SpreadingGround): void => {
        setSelectedSGRowValues(sgValues);
        setCaptureModalOpen(true);
    }

    const handleDeleteSG = async (id: number) => {
        setIsLoading(true);
        try {
            await spreadingGroundsService.delete(id);
            handleGetSGs();
            toast.info('Deleted Spreading Grounds Successfully ', { containerId: "manage-project-data-toast-container", autoClose: 5000, });

        } catch (err) {
            console.error("Failed to delete Spreading Ground ", err);
            toast.error('Failed to delete Spreading Grounds ', { containerId: "manage-project-data-toast-container", autoClose: 5000, });
        }
        setIsLoading(false);
        resetSelectedSGRowValues();
    };

    return (
        <div className="manage-project-data-page__LFD-container manage-project-data-page__fade-in ">
            <div className=" manage-project-data-page__LFD-container--header-container gradient">
                <h1 className="manage-project-data-page__LFD-container--header" >Spreading Grounds Projects</h1>
            </div>
            <div className="manage-project-data-page__shadow capture-dashboard__widget-container">
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1em" }}>
                    <h2 style={{ fontSize: "1.9em", textAlign: "left" }}>Project Details</h2>
                    <div style={{ display: "flex", marginBottom: "-1em" }}>
                        <Button
                            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1.5em" }}
                            onClick={handleCreateSGModal}
                            className="manage-project-data-page__button"
                        >
                            <p>
                                <Icon name="plus circle" size='large' style={{ color: 'white' }} /> Create New Spreading Ground
                            </p>
                        </Button>
                        <Button
                            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1.5em" }}
                            onClick={() => setUploadModalOpen(true)}
                            className="manage-project-data-page__button"
                        >
                            <p>
                                <Icon name="upload" size='large' style={{ color: 'white' }} />  Upload Monthly Capture
                            </p>
                        </Button>
                        <Button
                            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1.5em" }}
                            onClick={() => setCustomModalOpen(true)}
                            className="manage-project-data-page__button"
                        >
                            <p>
                                <Icon name="upload" size='large' style={{ color: 'white' }} /> Upload Custom Capture Event
                            </p>
                        </Button>

                    </div>
                </div>

                <Dimmer inverted active={isLoading}>
                    <Loader active={isLoading} />
                </Dimmer>
                <SGUITable
                    spreadingGroundsList={sgList}
                    editSG={handleEditSGModal}
                    deleteSG={handleDeleteSGModal}
                    viewCapture={handleOpenSGCaptureModal}
                />
            </div>

            {showSGModal && <SGFormModal
                isOpen={showSGModal}
                onClose={() => setShowSGModal(false)}
                sgValues={selectedSGRowValues}
                onSubmitEdit={handleEditSG}
                onSubmitCreate={handleCreateSG}
                resetValues={resetSelectedSGRowValues}
                typeOfBasinOpts={props.lookups.lutSpreadingGroundBasinType}
                watershedOptions={props.lookups.lutWatershed}
            />}

            {showDeleteSGModal &&
                <DeleteSGModal
                    sgName={selectedSGRowValues?.name}
                    sgId={selectedSGRowValues.id}
                    isOpen={showDeleteSGModal}
                    onClose={() => setShowDeleteSGModal(false)}
                    onDelete={handleDeleteSG}
                    resetValues={resetSelectedSGRowValues}
                />
            }
            <UploadSpreadingGroundModal
                isOpen={uploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
                refreshData={handleGetSGs}
            />
            <CustomSpreadingGroundModal
                isOpen={customModalOpen}
                onClose={() => setCustomModalOpen(false)}
                spreadingGrounds={sgList}
            />
            <SpreadingGroundCaptureModal
                isOpen={captureModalOpen}
                onClose={() => setCaptureModalOpen(false)}
                spreadingGround={selectedSGRowValues}
            />
        </div>
    );
};


export default SpreadingGroundsTab;