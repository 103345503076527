import { Translation } from "react-i18next";
import WelcomeViewModel from "../../models/viewModels/WelcomeViewModel";
import { WaterGauge } from "../waterGauge/WaterGauge";
import "./CoBenefits.scss";

interface ICoBenefitsProps {
    welcomeInfo: WelcomeViewModel;
}

export const CoBenefits = (props: ICoBenefitsProps) => {

    return (
        <Translation>
            {
                t =>
                    <>
                        <h1 className="benefits-title">Community Co-Benefits</h1>
                        <div className="benefits-wrapper">
                            <div className="hr-line"></div>
                            <div className="benefits-subtitle">
                                <p>Community Co-Benefits are the additional benefits resulting from Watershed Control Measures implemented to improve water quality. The 2021 Los Angeles Regional Municipal Separate Storm Sewer Systems (MS4s) Permit (Order No. R4-2021-0105) asks that Permittees report information for Community Co-Benefits such as reduces heat island effect, neighborhood beautification, water supply augmentation, benefits to Disadvantaged Communities (DACs), and provides parks and green spaces.</p>
                                <p>Each Community Co-Benefit bubble below summarizes the number of Watershed Control Measures implemented after December 28th, 2012, by participating Watershed Management Programs that provide that Community Co-Benefit. To learn more about participating Watershed Management Programs, please visit their Progress Reporting page, accessible through the Home page.</p>
                            </div>
                        </div>
                        <div className="cobenefits-wrapper">
                            <div className="left-div">
                                <div className="sub-header">
                                    Totals
                                </div>
                                <div className="card-wrapper">
                                    <div className='water-gauge-container container-top'>

                                        {/* Number of Projects */}
                                        <WaterGauge
                                            value={100}
                                            size={60}
                                            color="#178BCA"
                                            displayValue={isNaN(props.welcomeInfo.numberOfProjects) ?
                                                0
                                                : props.welcomeInfo.numberOfProjects}
                                            offsetX={0}
                                            offsetY={12}
                                            numberSize={1} //size of number inside bubble
                                            metricColor="#178BCA" //color of metric label text
                                            metricSize="metric-small" //size of metric label text
                                        />
                                        <div className="water-gauge-text" style={{ color: "#178BCA" }}>
                                            {t('welcomeProjCountBubble')}
                                        </div>

                                    </div>
                                    <div className='water-gauge-container container-bottom'>

                                        {/* Volume of Water Captured */}
                                        <WaterGauge
                                            value={75}
                                            size={60}
                                            color="#FBBD08"
                                            displayValue={props.welcomeInfo.totalWaterConserved}
                                            offsetX={0}
                                            offsetY={12}
                                            numberSize={1} //size of number inside bubble
                                            metricSize="metric-small" //size of metric label text
                                            metricColor="#b17739" //color of metric label text
                                            metric={t('genUnitAcFt')}
                                        />

                                        <div className="water-gauge-text" style={{ color: "#ce9b03" }}>
                                            {t('welcomeVolConservedBubble')}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="hr-rule">{/* empty div to create vertical line */}
                            </div>

                            <div className="right-div">
                                <div className="sub-header">
                                    {t('welcomeCoBenefits')}
                                </div>
                                <div className="top-row">
                                    <div className='water-gauge-container'>
                                        {/* Reduces Heat Island Effect */}
                                        <WaterGauge
                                            // water level displays as a percentage of # of total projects
                                            value={isNaN(props.welcomeInfo.numberHeatIslandBenefit) ?
                                                0
                                                : props.welcomeInfo.numberHeatIslandBenefit / props.welcomeInfo.numberOfProjects * 100}
                                            size={60}
                                            color="#906c51"
                                            displayValue={isNaN(props.welcomeInfo.numberHeatIslandBenefit) ?
                                                0
                                                : Math.round(props.welcomeInfo.numberHeatIslandBenefit)}
                                            offsetX={0}
                                            offsetY={12}
                                            numberSize={1} //size of number inside bubble
                                            metricColor="#906c51"
                                        />
                                        <div className="water-gauge-text" style={{ color: "#906c51" }}>
                                            {t('welcomeCoBenefit2')}
                                        </div>
                                    </div>
                                    <div className='water-gauge-container'>
                                        {/* Neighborhood Beautification */}
                                        <WaterGauge
                                            // water level displays as a percentage of # of total projects
                                            value={isNaN(props.welcomeInfo.numberBeautificationBenefit) ?
                                                0
                                                : props.welcomeInfo.numberBeautificationBenefit / props.welcomeInfo.numberOfProjects * 100}
                                            size={60}
                                            color="#cd7532"
                                            displayValue={isNaN(props.welcomeInfo.numberBeautificationBenefit) ?
                                                0
                                                : Math.round(props.welcomeInfo.numberBeautificationBenefit)}
                                            offsetX={0}
                                            offsetY={12}
                                            numberSize={1} //size of number inside bubble
                                            metricColor="#cd7532"
                                        />
                                        <div className="water-gauge-text" style={{ color: "#cd7532" }}>
                                            {t('welcomeCoBenefit3')}
                                        </div>
                                    </div>
                                    <div className='water-gauge-container'>
                                        {/* Water Supply Augmentation */}
                                        <WaterGauge
                                            // water level displays as a percentage of # of total projects
                                            value={isNaN(props.welcomeInfo.numberWaterSupplyBenefit) ?
                                                0
                                                : props.welcomeInfo.numberWaterSupplyBenefit / props.welcomeInfo.numberOfProjects * 100}
                                            size={60}
                                            color="#A82431"
                                            displayValue={isNaN(props.welcomeInfo.numberWaterSupplyBenefit) ?
                                                0
                                                : Math.round(props.welcomeInfo.numberWaterSupplyBenefit)}
                                            offsetX={0}
                                            offsetY={12}
                                            numberSize={1} //size of number inside bubble
                                            metricColor="#A82431"
                                        />
                                        <div className="water-gauge-text" style={{ color: "#A82431" }}>
                                            {t('welcomeCoBenefit5')}
                                        </div>
                                    </div>

                                </div>
                                <div className="bottom-row">
                                    <div className='water-gauge-container'>
                                        {/* Benefits to DACs */}
                                        <WaterGauge
                                            // water level displays as a percentage of # of total projects
                                            value={isNaN(props.welcomeInfo.numberDacBenefit) ?
                                                0
                                                : props.welcomeInfo.numberDacBenefit / props.welcomeInfo.numberOfProjects * 100}
                                            size={60}
                                            color="#EC9BA9"
                                            displayValue={isNaN(props.welcomeInfo.numberDacBenefit) ?
                                                0
                                                : Math.round(props.welcomeInfo.numberDacBenefit)}
                                            offsetX={0}
                                            offsetY={12}
                                            numberSize={1} //size of number inside bubble
                                            metricColor="#EC9BA9"
                                        />
                                        <div className="water-gauge-text" style={{ color: "#DC97A2" }}>
                                            {t('welcomeCoBenefit6')}
                                        </div>
                                    </div>
                                    <div className='water-gauge-container'>
                                        {/* More Parks & Green Spaces */}
                                        <WaterGauge
                                            // water level displays as a percentage of # of total projects
                                            value={isNaN(props.welcomeInfo.numberParkBenefit) ?
                                                0
                                                : (props.welcomeInfo.numberParkBenefit / props.welcomeInfo.numberOfProjects * 100)}
                                            size={60}
                                            color="#4B7F52"
                                            displayValue={isNaN(props.welcomeInfo.numberParkBenefit) ?
                                                0
                                                : Math.round(props.welcomeInfo.numberParkBenefit)}
                                            offsetX={0}
                                            offsetY={12}
                                            numberSize={1} //size of number inside bubble
                                            metricColor="#4B7F52"
                                        />
                                        <div className="water-gauge-text" style={{ color: "#4B7F52" }}>
                                            {t('welcomeCoBenefit1').replace("and", "&")} {/* takes out "and" and replaces with "&" so text will fit on card */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </Translation >
    )
}