import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { Button, Dimmer, Loader, Menu, Segment, Table } from "semantic-ui-react";
import axios from "axios";
import qs from "qs";

import { PrettyProjectPageTypes, SidebarTypes } from "../../mainWrapper/MainWrapper";
import { ExcelUploadModal } from "../../modals/excelUploadModal/ExcelUploadModal";
import { uploadOptions } from "../../../customTypes/stringLiterals";
import BmpGeneral from "../../../models/BmpGeneral";
import LookupFactory from "../../../lookups/LookupFactory";
import { useAPIFetchAllLocal } from "../../../core/UseAPILocal";
import QueueRequest from "../../../models/QueueRequest";
import Config from "../../../config/Config";
import { FilterComponent, ManageProjectFilter } from "../filterComponent/FilterComponent";
import { QueueModal } from "../../modals/QueueModal/QueueModal";
import QueueItemModel from "../../../models/viewModels/QueueItemModel";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import DropdownLutH2O from "../../../paradigmLib/dropdownLut/DropdownLut";
import { TooltipModal } from "../../modals/tooltipModal/TooltipModal";
import Download, { GetById } from "../../../core/DownloadUtil";
import { toast, ToastContainer } from "react-toastify";
import { ConfirmArchiveModal } from "../../modals/ConfirmArchiveModal/ConfirmArchiveModal";
import { ProjectList } from "./ProjectList";
import { ConfirmDeleteModal } from "../../modals/ConfirmDeleteModal/ConfirmDeleteModal";
import MpdMapConfig from "./MpdMapConfig";
import Map, { GeoJSON, Layer, MapProps, WMS } from "@paradigmh2o/react-map";
import inProgressWCM from '../../../images/InProgressWCMMarker.png';
import CompleteWCMMarker from '../../../images/CompleteWCMMarker.png';
import newRedev from '../../../images/newRedevMarker.png';
import lfdMarker from '../../../images/LowFlowDiversionMarker.png';
import spreadingGroundsMarker from '../../../images/SpreadingGroundMarker.png';
import { Legend } from "../buildWatershedForm/wmgPublicDashboard/Map/Legend";
import Extent from "@arcgis/core/geometry/Extent";
import { GeoJsonService } from "../../../services/GeoJsonService";
import { BmpClassId } from "../../../models/lutModels/LutBmpClass";
import { LowFlowDiversionTab } from "./LowFlowDiversionTab";
import SpreadingGroundsTab from "./SpreadingGroundsTab";
import { SpreadingGroundService } from "../../../services/SpreadingGroundService";

import "./ManageProjectData.scss";
import Jurisdiction from "../../../models/Jurisdiction";
import { CaptureDashboardConfigTab } from "./CaptureDashboardConfigTab";
import { LowFlowDiversionService } from "../../../services/LowFlowDiversionService";

export interface IManageProjectDataProps {
    setSidebarProjectInfoObj: Function;
    dispatchSidebar: Function;
    lookups: LookupFactory;
    refreshTable: boolean;
    setRefreshTable: Function;
    jurisdictionList: Jurisdiction[];
}

export class BmpSearchResponse {
    bmps: BmpGeneral[];
    pageCount: number;
    page: number;
}

export const ManageProjectData: React.FC<IManageProjectDataProps> = (props: IManageProjectDataProps) => {
    const auth = useAuth();
    const lfdService = useMemo(() => new LowFlowDiversionService(auth.user?.access_token), [auth]);

    const [fileUploaded, setFileUploaded] = useState<uploadOptions>(
        uploadOptions.Waiting);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [bmpGeneral, setBmpGeneral] = useState<Array<BmpGeneral>>(new Array<BmpGeneral>());
    const [activeItem, setActiveItem] = useState<"Uploaded Project List" | "Bulk Uploads" | "Project Map" | "LFD" | "Spreading Grounds" | "Capture Dashboard Configuration">("Uploaded Project List");

    const [queueItemList, setQueueItemList] = useState<Array<QueueItemModel>>(new Array<QueueItemModel>());
    const [sortedQueueItemList, setSortedQueueItemList] = useState<Array<QueueItemModel>>(new Array<QueueItemModel>());
    const [fetchingQueueItemList, setFetchingQueueItemList] = useState<boolean>(false);

    const [bmpEndpoint] = useState<string>(Config.api_endpoint + "bmp/search");
    const [bmpEndpointRem] = useState<string>(Config.api_endpoint + "bmp/delete");

    const [selectedJurisdictionId, setSelectedJurisdictionId] = useState<number>(props.jurisdictionList[0].id); // default to first jurisdiction

    const [filter, setFilter] = useState<ManageProjectFilter>(new ManageProjectFilter());
    const [finalFilter, setFinalFilter] = useState<ManageProjectFilter>(new ManageProjectFilter());

    const [refreshMap, setRefreshMap] = useState<boolean>(false);
    const [extent, setExtent] = useState<Extent>();

    const [checkedSet, setCheckedSet] = useState<Set<number>>(new Set<number>());

    const geoJsonService = useMemo(() => new GeoJsonService(auth.user?.access_token), [auth]);
    const spreadingGroundsService = useMemo(() => new SpreadingGroundService(auth.user?.access_token), [auth]);

    const [showLowFlowDiversions, setShowLowFlowDiversions] = useState<boolean>(false);

    useEffect(() => {
        if (activeItem === "Project Map") {
            setRefreshMap(true);
        }
    }, [activeItem])

    useEffect(() => {
        if (refreshMap === true) {
            setTimeout(() => setRefreshMap(false), 500);
        }
    }, [refreshMap])

    useAPIFetchAllLocal(auth.user?.access_token, new QueueItemModel(), fetchingQueueItemList, setFetchingQueueItemList, setQueueItemList, { jurisdictionId: selectedJurisdictionId, active: 1 }, (data) => {
    });

    const updateSelection = (id: number) => {
        let newSet = new Set(checkedSet);
        if (newSet.has(id)) {// remove
            newSet.delete(id);
        } else {// add
            newSet.add(id);
        }
        setCheckedSet(newSet);
    }

    const updateSelectionAll = () => {
        if (checkedSet.size === bmpGeneral.length) { // something is in the set, unselect
            let newSet = new Set<number>();
            setCheckedSet(newSet);
        } else { // select all
            let newSet = new Set<number>();
            bmpGeneral.forEach((item) => { newSet.add(item.id); });
            setCheckedSet(newSet);
        }
    }

    const deleteSelected = () => {
        axios.post<BmpGeneral>(bmpEndpointRem,
            Array.from(checkedSet),
            {
                headers: {
                    "Authorization": `Bearer ${auth.user?.access_token}`,
                    method: 'POST'
                }
            })
            .then((response) => {
                setCheckedSet(new Set<number>());
                getData();
            });
    }

    const deleteBulkUpload = (id: number) => {
        const deleteEndpoint = Config.api_endpoint + "QueueItem/SetQueueItemActiveToZero";

        var form = new FormData();
        form.append("queueItemId", id.toString());

        axios.put<string>(deleteEndpoint, form, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        })
            .then((response) => {
                // refresh table
                setFetchingQueueItemList(true);
            })
            .catch(() => {
                console.error('deleteConfirmModal could not delete file');
            });
    }

    const controllerRef = useRef<AbortController | null>();

    const getData = useCallback(() => {
        if (finalFilter === undefined || finalFilter.jurisdictions === undefined || finalFilter.jurisdictions.length === 0) { return; }

        if (controllerRef.current) { controllerRef.current.abort(); }
        const controller = new AbortController();
        controllerRef.current = controller;

        axios.get<BmpSearchResponse>(bmpEndpoint,
            {
                headers: {
                    "Authorization": "Bearer " + auth.user?.access_token
                },
                signal: controllerRef.current?.signal,
                params: finalFilter,
                paramsSerializer: (params: any) => { return qs.stringify(params); }
            })
            .then((response) => {
                response.data.bmps.map((bmp: BmpGeneral) => {
                    return bmp;
                })
                setBmpGeneral(response.data.bmps);
                setCheckedSet(new Set<number>());
                controllerRef.current = null;
                setIsLoading(false);
                props.setRefreshTable(false);
            })
            .catch(function (thrown) {
                if (axios.isCancel) { console.warn("Promise Aborted"); }
            });
    }, [bmpEndpoint, finalFilter, auth, controllerRef]);

    const onFilterChanged = useCallback((filterFromComponent: ManageProjectFilter) => {
        if (!filterFromComponent || !selectedJurisdictionId) {
            return;
        }
        setFilter(filterFromComponent);
    }, [selectedJurisdictionId]);

    const onInfoClick = async (bmpId: number, viewType: SidebarTypes) => {
        const fullProject = await GetById<BmpGeneral>(
            BmpGeneral.endpoint,
            auth.user?.access_token,
            bmpId);

        props.setSidebarProjectInfoObj({ ...fullProject });
        props.dispatchSidebar({
            type: "open",
            payload: { name: viewType, content: { showEditButton: true, prettyProjectPageType: PrettyProjectPageTypes.BmpGeneral } },
        });
    };

    useEffect(() => {
        if (queueItemList.length > 0) {
            const sortedQueueItemList = queueItemList.sort((a, b) => b.created.valueOf() < a.created.valueOf() ? -1 : 1);
            setSortedQueueItemList(sortedQueueItemList);
        } else {
            setSortedQueueItemList(new Array<QueueItemModel>());
        }
    }, [queueItemList]);

    useEffect(() => {
        setIsLoading(true);
        var selectedJurisdiction = props.lookups.lutJurisdiction.find(j => j.id === selectedJurisdictionId);
        var finalFilterTemp = {
            ...filter,
            jurisdictions: [selectedJurisdiction.internalName],
        };

        setFinalFilter(finalFilterTemp);
    }, [filter, props.lookups, selectedJurisdictionId]);

    useEffect(() => {
        getData();
    }, [finalFilter]);

    useEffect(() => {
        if (props.refreshTable === true) {
            getData();
        }
    }, [props.refreshTable]);

    const downloadWcm = async () => {
        const endpoint = Config.api_endpoint + "bmp/wcmbulkdownload?" + qs.stringify(finalFilter);

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container" });
        try {
            await Download(endpoint, auth.user?.access_token, "Watershed Control Measures.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    }

    const downloadNewRedevelopment = async () => {
        const endpoint = Config.api_endpoint + "bmp/newredevelopmentdownload?" + qs.stringify(finalFilter);

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container" });
        try {
            await Download(endpoint, auth.user?.access_token, "New & Redevelopment.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    };

    const downloadTemplateWcm = async () => {
        const endpoint = Config.api_endpoint + "BulkUploadDocument/WatershedControlMeasureTemplate";

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container" });
        try {
            await Download(endpoint, auth.user?.access_token, "watershed_control_measures_bulk_upload.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    }

    const downloadTemplateNar = async () => {
        const endpoint = Config.api_endpoint + "BulkUploadDocument/NewRedevelopmentTemplate";

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container" });
        try {
            await Download(endpoint, auth.user?.access_token, "new_redevelopment_bulk_upload.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    }

    const geoJsonClick = useCallback(async (e: any) => {

        // get the full project info and open the sidebar
        if (!e.results[0] || !e.results[0]?.graphic.attributes.bmpGeneralId) {
            return;
        }

        let fullProject = await GetById<BmpGeneral>(
            BmpGeneral.endpoint,
            auth.user?.access_token,
            e.results[0]?.graphic.attributes.bmpGeneralId
        )

        props.setSidebarProjectInfoObj({ ...fullProject });
        props.dispatchSidebar({
            type: "open",
            payload: { name: "PrettyProjectPage", content: { showEditButton: true, prettyProjectPageType: PrettyProjectPageTypes.BmpGeneral } },
        });
    }, [auth.user?.access_token, props])

    const lfdLayer = useMemo(() => {
        return new GeoJSON(
            "lowFlowDiversions",
            "Low Flow Diversions",
            `${Config.api_endpoint}GeoJson/LfdMapData`,
            true,
            true,
            { //renderer
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    url: lfdMarker,
                    width: "38px",
                    height: "38px"
                }
            },
            async (e: any) => { // the `any` is required here because results.graphic isnt typed
                if (!e.results[0] || !e.results[0]?.graphic.attributes.bmpGeneralId) {
                    return;
                }

                let lfd = await lfdService.getById(e.results[0].graphic.attributes.bmpGeneralId);

                props.dispatchSidebar({
                    type: "open",
                    payload: { name: "PrettyProjectPage", content: { lowFlowDiversion: lfd, prettyProjectPageType: PrettyProjectPageTypes.LowFlowDiversion } },
                });
            },
            null,
            null,
            { Authorization: "Bearer " + auth.user?.access_token }
        )
    }, [auth.user?.access_token, lfdService, props]);

    const spreadingGroundsLayer = useMemo(() => {
        return [
            new GeoJSON(
                "spreadingGroundsPoly",
                "Spreading Ground Footprints",
                `${Config.api_endpoint}SpreadingGrounds/GeoJson/Footprint`,
                true,
                true,
                { //renderer
                    type: "simple",
                    symbol: {
                        type: "simple-fill",
                        color: [123, 456, 789, 0.4],
                        style: "solid",
                        outline: {
                            color: "#432786",
                            width: 1
                        }
                    }
                },
                null,
                null,
                null,
                { Authorization: "Bearer " + auth.user?.access_token }
            ),

            new GeoJSON(
                "spreadingGroundsPoints",
                "Spreading Grounds",
                `${Config.api_endpoint}SpreadingGrounds/GeoJson/Point`,
                true,
                true,
                { //renderer
                    type: "simple",
                    symbol: {
                        type: "picture-marker",
                        url: spreadingGroundsMarker,
                        width: "38px",
                        height: "38px"
                    }
                },
                async (e: __esri.HitTestResult) => {
                    if (!e.results[0] || !(e.results[0] as __esri.GraphicHit)?.graphic.attributes.id) {
                        return;
                    }
                    const spreadingGroundsData = await spreadingGroundsService.getById((e.results[0] as __esri.GraphicHit)?.graphic.attributes.id);
                    const spreadingGroundsCaptureData = await spreadingGroundsService.getBenefitById((e.results[0] as __esri.GraphicHit)?.graphic.attributes.id);

                    props.dispatchSidebar({
                        type: "open",
                        payload: {
                            name: "PrettyProjectPage",
                            content: {
                                spreadingGroundsData, spreadingGroundsCaptureData, prettyProjectPageType: PrettyProjectPageTypes.SpreadingGround
                            }
                        },
                    });
                },
                null,
                null,
                { Authorization: "Bearer " + auth.user?.access_token }
            ),
        ]
    }, [auth.user?.access_token, props, spreadingGroundsService]);

    const subLayers = useMemo(() => {
        return [
            { name: "Safe Clean Water Program Watershed Areas", viz: true },
            { name: "Jurisdictions", viz: true },
            { name: "Watershed Management Groups (WMGs)", viz: true },
            { name: "Subwatersheds", viz: false },
            { name: "Major Watersheds", viz: false },
            { name: "Channels(Drainage)", viz: true },
            { name: "Storm Drains", viz: false }
        ];
    }, [])

    const handleJurisdictionChange = useCallback((_: any, d: any) => {
        const jurisdictionId: number = d.value;

        // Get Map Extent
        (async function () {
            var extent = await geoJsonService.getExtentForJurisdiction(jurisdictionId);
            if (extent.ymin === -extent.ymax) {
                return;
            }
            setExtent(extent);
        })();

        // Get Queue Data
        setFetchingQueueItemList(true);

        setShowLowFlowDiversions(jurisdictionId === 1089);

        // Set the layers
        setLayers((layersList) => {
            let updatedLayers = [
                ...subLayers.map((subLayer, i) => {
                    return new WMS(
                        i.toString(),
                        subLayer.name,
                        Config.map_connection_url,
                        subLayer.viz,
                        [
                            subLayer.name
                        ],
                    );
                }),
                new GeoJSON(
                    "New & Redevelopment Projects",
                    "New and Redevelopment",
                    `${Config.api_endpoint}GeoJson/GetMapBmpData/${jurisdictionId}/${BmpClassId.NewAndRedevelopment}`,
                    true,
                    false,
                    {
                        type: "simple",
                        symbol: {
                            type: "picture-marker",
                            url: newRedev,
                            width: "15px",
                            height: "15px"
                        }
                    },
                    geoJsonClick,
                    null,
                    null,
                    { Authorization: "Bearer " + auth.user?.access_token }
                ),
                new GeoJSON(
                    "inProgressWCMs",
                    "In-Progress WCMs",
                    `${Config.api_endpoint}GeoJson/GetMapBmpData/${jurisdictionId}/${BmpClassId.WatershedControlMeasure}/1000`,
                    true,
                    true,
                    {
                        type: "simple",
                        symbol: {
                            type: "picture-marker",
                            url: inProgressWCM,
                            width: "38px",
                            height: "38px"
                        }
                    },
                    geoJsonClick,
                    null,
                    null,
                    { Authorization: "Bearer " + auth.user?.access_token }
                ),
                new GeoJSON(
                    "completedWCMs",
                    "Completed WCMs",
                    `${Config.api_endpoint}GeoJson/GetMapBmpData/${jurisdictionId}/${BmpClassId.WatershedControlMeasure}/1001`,
                    true,
                    true,
                    { //renderer
                        type: "simple",
                        symbol: {
                            type: "picture-marker",
                            url: CompleteWCMMarker,
                            width: "38px",
                            height: "38px"
                        }
                    },
                    geoJsonClick,
                    null,
                    null,
                    { Authorization: "Bearer " + auth.user?.access_token }
                )
            ];
            if (jurisdictionId === 1089) {
                return [...updatedLayers.slice(0, updatedLayers.length - 3), lfdLayer, ...spreadingGroundsLayer, ...updatedLayers.slice(updatedLayers.length - 3)];
            }

            return updatedLayers;
        });

        // Finally, set the selected jurisdiction
        setSelectedJurisdictionId(d.value);
    }, [auth.user?.access_token, geoJsonClick, geoJsonService, lfdLayer, spreadingGroundsLayer, subLayers]);

    const [layers, setLayers] = useState<Layer[]>([
        ...subLayers.map((subLayer, i) => {
            return new WMS(
                i.toString(),
                subLayer.name,
                Config.map_connection_url,
                subLayer.viz,
                [
                    subLayer.name
                ],
            );
        }),
        new GeoJSON(
            "New & Redevelopment Projects",
            "New and Redevelopment",
            `${Config.api_endpoint}GeoJson/GetMapBmpData/${props.jurisdictionList[0].id}/${BmpClassId.NewAndRedevelopment}`,
            true,
            false,
            {
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    url: newRedev,
                    width: "15px",
                    height: "15px"
                }
            },
            geoJsonClick,
            null,
            null,
            { Authorization: "Bearer " + auth.user?.access_token }
        ),
        new GeoJSON(
            "inProgressWCMs",
            "In-Progress WCMs",
            `${Config.api_endpoint}GeoJson/GetMapBmpData/${props.jurisdictionList[0].id}/${BmpClassId.WatershedControlMeasure}/1000`,
            true,
            true,
            {
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    url: inProgressWCM,
                    width: "38px",
                    height: "38px"
                }
            },
            geoJsonClick,
            null,
            null,
            { Authorization: "Bearer " + auth.user?.access_token }
        ),
        new GeoJSON(
            "completedWCMs",
            "Completed WCMs",
            `${Config.api_endpoint}GeoJson/GetMapBmpData/${props.jurisdictionList[0].id}/${BmpClassId.WatershedControlMeasure}/1001`,
            true,
            true,
            { //renderer
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    url: CompleteWCMMarker,
                    width: "38px",
                    height: "38px"
                }
            },
            geoJsonClick,
            null,
            null,
            { Authorization: "Bearer " + auth.user?.access_token }
        )
    ]);

    const toggleLayerVisibility = useCallback((layer: Layer) => {
        layers
            .filter(l => l === layer)
            .forEach(l => l.visible = !l.visible);
        setLayers([...layers]);
    }, [layers]);

    return (
        <div className="manage-project-data-page">
            <ToastContainer enableMultiContainer containerId={"manage-project-data-toast-container"} position="top-center" hideProgressBar={true} autoClose={false} />
            <div className="content-div">
                <div className="jurisdiction-dropdown-wrapper pb-2">
                    <DropdownLutH2O
                        dropdownItems={props.jurisdictionList}
                        dropdownTitle={"Jurisdiction"}
                        isMultiselect={false}
                        nullLabel={null}
                        onSelect={handleJurisdictionChange}
                        search={true}
                        parentId={selectedJurisdictionId}
                    />
                </div>
                <div className="jurisdiction-title jurisdiction-div">
                    {props.lookups
                        ? props.lookups.getJurisdictionById(
                            selectedJurisdictionId
                        )
                        : ""}
                    <hr />
                </div>
                <div className="sub-header">Manage Project Data</div>
                <div className="menu-items">
                    <Menu pointing secondary>
                        <Menu.Item
                            name="Uploaded Project List"
                            active={activeItem === "Uploaded Project List"}
                            onClick={() => setActiveItem("Uploaded Project List")}
                            content="Uploaded Projects"
                            style={{ color: "#ff0000" }}
                        />
                        <Menu.Item
                            name="Bulk Uploads"
                            active={activeItem === "Bulk Uploads"}
                            onClick={() => setActiveItem("Bulk Uploads")}
                            content="Upload Project Data"
                        />
                        <Menu.Item
                            name="Project Map"
                            active={activeItem === "Project Map"}
                            onClick={() => setActiveItem("Project Map")}
                            content="Map"
                        />
                        {selectedJurisdictionId === 1089 &&
                            <Menu.Item
                                name="LFDs"
                                active={activeItem === "LFD"}
                                onClick={() => setActiveItem("LFD")}
                                content="LFDs"
                            />}
                        {selectedJurisdictionId === 1089 &&
                            <Menu.Item
                                name="Spreading Grounds"
                                active={activeItem === "Spreading Grounds"}
                                onClick={() => setActiveItem("Spreading Grounds")}
                                content="Spreading Grounds"
                            />}
                        <Menu.Item
                            name="Capture Dashboard Configuration"
                            active={activeItem === "Capture Dashboard Configuration"}
                            onClick={() => setActiveItem("Capture Dashboard Configuration")}
                            content="Capture Dashboard Configuration"
                        />
                    </Menu>
                </div>
                <div className="manage-project-data-container manage-project-data-page__fade-in">
                    {/* UPLOADED PROJECTS TAB */}
                    {
                        activeItem === "Uploaded Project List" &&
                        <>
                            <FilterComponent
                                currentJurisdictionId={selectedJurisdictionId}
                                onChange={onFilterChanged}
                                lookups={props.lookups} />
                            <div className="hr-line"></div>
                            <div className="sub-header2">
                                Uploaded Project List
                            </div>
                            {!(isLoading)
                                ? <div className="sub-header3">{bmpGeneral.length} projects loaded.</div>
                                : <div className="sub-header3">. . .</div>
                            }
                            <Segment>
                                <Dimmer inverted active={isLoading}>
                                    <Loader active={isLoading} />
                                </Dimmer>
                                <div className="download-header">
                                    <div className="spacer-div"></div>
                                    <div className="spacer-div pb-1">
                                        {
                                            bmpGeneral.some(bmp => bmp.bmpClassId === BmpClassId.WatershedControlMeasure || bmp.bmpClassId === BmpClassId.NonStructural) &&
                                            <Button
                                                className="primaryBtn mr-1"
                                                onClick={downloadWcm}
                                                content="Bulk Download Watershed Control Measures"
                                            />
                                        }
                                        {
                                            bmpGeneral.some(bmp => bmp.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                            <Button
                                                className="primaryBtn ml-1"
                                                onClick={downloadNewRedevelopment}
                                                content="Bulk Download New &amp; Redevelopment Projects"
                                            />
                                        }
                                    </div>
                                    <div className="spacer-div align-right">
                                        {
                                            checkedSet.size > 0 &&
                                            <div className="delete-icon">
                                                <span>Delete Selected Items</span>
                                                <ConfirmDeleteModal theme={'red'} icon='trash alternate outline' confirmDeleteFunc={deleteSelected} itemToDelete={checkedSet} displayName={`${checkedSet.size} item` + (checkedSet.size > 1 ? `s` : ``)} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <ProjectList
                                    lookups={props.lookups}
                                    bmpGeneral={bmpGeneral}
                                    onInfoClick={onInfoClick}
                                    editFunctions={{ updateSelection, updateSelectionAll, checkedSet }}
                                    publicOrInternal="internal"
                                    prettyProjectButtonTitle="Summary"
                                />
                            </Segment>
                        </>
                    }
                    {/* UPLOAD PROJECT DATA TAB */}
                    {
                        activeItem === "Bulk Uploads" &&
                        <>
                            <div className="queue-btn-wrapper">
                                {/* UPLOADS PROJECTS BUTTON */}
                                <div className="upload-button-and-instructions">
                                    <ExcelUploadModal
                                        theme={"secondaryBtn"}
                                        fileUploaded={fileUploaded}
                                        setFileUploaded={setFileUploaded}
                                        attachmentModel={QueueRequest}
                                        btnLabel="&nbsp;&nbsp;UPLOAD PROJECTS"
                                        reFetchListFunc={setFetchingQueueItemList}
                                        selectedJurisdictionId={selectedJurisdictionId}
                                    />
                                    <TooltipModal />
                                </div>
                                {/* WSCM TEMPLATE BUTTON */}
                                <ButtonComponent
                                    onBtnClick={downloadTemplateWcm}
                                    label="Download Watershed Control Measures Template"
                                    theme="primaryBtn ml-1"
                                />
                                {/* NEW & REDEV TEMPLATE BUTTON */}
                                <ButtonComponent
                                    onBtnClick={downloadTemplateNar}
                                    label="Download New &amp; Redevelopment Template"
                                    theme="primaryBtn ml-1"
                                />
                                {/* REFRESH TABLE BUTTON */}
                                <ButtonComponent
                                    onBtnClick={() => setFetchingQueueItemList(true)}
                                    label="Refresh Table"
                                    disabled={fetchingQueueItemList}
                                    loading={fetchingQueueItemList}
                                    theme={"primaryBtn"}
                                />
                            </div>
                            <div className="sub-header2">
                                Recent Bulk Uploads <br />
                                <div className="note">(Failed uploads can be archived from table)</div>
                            </div>

                            <Table celled fixed >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width="2">File Name</Table.HeaderCell>
                                        <Table.HeaderCell width="2">Upload Date</Table.HeaderCell>
                                        <Table.HeaderCell width="1">Step</Table.HeaderCell>
                                        <Table.HeaderCell width="1">Status</Table.HeaderCell>
                                        <Table.HeaderCell width="1">Projects Uploaded Count</Table.HeaderCell>
                                        <Table.HeaderCell width="1" className="center">Error(s) / Warning(s)</Table.HeaderCell>
                                        <Table.HeaderCell width="2">Finish Date</Table.HeaderCell>
                                        <Table.HeaderCell width="1" className="center">Archive</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        // newest items first
                                        sortedQueueItemList.map((item: QueueItemModel, index: number) => {
                                            return (<Table.Row key={index + item.fileName}>
                                                <Table.Cell>{item.fileName}</Table.Cell>
                                                {/* Upload Date */}
                                                <Table.Cell>{new Date(item.created).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</Table.Cell>
                                                <Table.Cell>{props.lookups ? props.lookups.getQueueStepProcessById(item.queueStepProcessId) : "N/A"}</Table.Cell>
                                                <Table.Cell>{props.lookups ? props.lookups.getQueueStatusById(item.queueStatusId) : "N/A"}</Table.Cell>
                                                <Table.Cell>{(item.totalProjects && item.queueStatusId === props.lookups.getQueueStatusByInternalName("complete")) ? (item.totalProjects + " (" + item.duplicateCount + " Duplicates)") : "N/A"}</Table.Cell>
                                                <Table.Cell className="center">
                                                    {(props.lookups.getQueueStatusById(item.queueStatusId) !== "Complete" || item.duplicateCount > 0 || item.warningCount > 0) &&
                                                        <QueueModal queueItemId={item.id} theme={"primary"} icon={"info circle"} queueError={item.queueError} lookups={props.lookups} />
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>{item.finishDate ? new Date(item.finishDate).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) : "N/A"}</Table.Cell>
                                                <Table.Cell className="center">
                                                    {
                                                        props.lookups && item.queueStatusId === 1003 ?
                                                            <ConfirmArchiveModal theme={'blue'} icon='trash' displayName={item.fileName} confirmArchiveFunc={deleteBulkUpload} itemToArchive={item.id} />
                                                            : ""
                                                    }
                                                </Table.Cell>
                                            </Table.Row>)
                                        })
                                    }
                                </Table.Body>
                            </Table>
                        </>
                    }
                    {/* MAP TAB */}
                    {activeItem === "Project Map" &&
                        <div className="manage-proj-data-map-div">
                            <div className="map-box">
                                <div className="map" style={{ height: "800px", width: "100%" }}>
                                    <Map {...MpdMapConfig as MapProps}
                                        layers={[...layers]}
                                        onMapViewCreated={() => { }}
                                        extent={extent}
                                        zoomWidgetPosition="top-left"
                                        legend={(layers: Array<Layer>) =>
                                            <Legend
                                                layers={layers}
                                                toggleVisibility={toggleLayerVisibility}
                                                topUnhiddenLayers={showLowFlowDiversions ? 4 : 3}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {/* LFD TAB */}
                    {activeItem === "LFD" && selectedJurisdictionId === 1089 &&
                        <LowFlowDiversionTab
                            lookups={props.lookups}
                        />
                    }
                    {activeItem === "Spreading Grounds" && selectedJurisdictionId === 1089 &&
                        <SpreadingGroundsTab
                            lookups={props.lookups}
                        />
                    }
                    {activeItem === "Capture Dashboard Configuration" &&
                        <CaptureDashboardConfigTab
                            lookups={props.lookups}
                            selectedJurisdictionId={selectedJurisdictionId}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

