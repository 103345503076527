import React, { useEffect, useMemo, useState } from "react";
import { Icon, Menu } from "semantic-ui-react";
import LookupFactory from "../../../lookups/LookupFactory";
import { WaterGauge } from "../../waterGauge/WaterGauge";
import { customFormat, dateDMY, convertToAmPm } from "../../../core/NumberFormat";
import Wave from "../../../images/wave3.svg";
import Wave2 from "../../../images/upsideDownWave5.svg";
import { PrettyProjectPageSection } from "./PrettyProjectPage";
import "./PrettyProjectPage.scss";
import { LowFlowDiversion } from "../../../models/LowFlowDiversion";
import { useAuth } from "react-oidc-context";
import { LowFlowDiversionService } from "../../../services/LowFlowDiversionService";
import { CaptureBenefit } from "../../../models/CaptureBenefit";

export type PrettyProjectPageLfdProps = {
    lookups: LookupFactory;
    setSidebarVisible?: Function;
    lowFlowDiversion: LowFlowDiversion;
}

export const PrettyProjectPageLfd: React.FC<PrettyProjectPageLfdProps> = (props: PrettyProjectPageLfdProps) => {
    const auth = useAuth();
    const lfdService = useMemo(() => new LowFlowDiversionService(auth.user?.access_token), [auth]);
    const [activeItem, setActiveItem] = useState<PrettyProjectPageSection>("overview");
    const [lfdBenefits, setLfdBenefits] = useState<number | null>(null);
    const [latestCapture, setLatestCapture] = useState<CaptureBenefit| null>(null);

    // Get Lfd Benefits
    useEffect(() => {
        lfdService.getBenefitById(props.lowFlowDiversion.id)
            .then((benefits) => {
                setLfdBenefits(benefits.reduce((acc, benefit) => acc + benefit.amount, 0));
                setLatestCapture(benefits.sort((a, b) => new Date(b.eventEnd).getTime() - new Date(a.eventEnd).getTime())[0]);
            })
            .catch((e) => {
                console.error('error getting LFD Benefits', e)
            });
    }, [lfdService, props.lowFlowDiversion.id]);

    return (
        <div className="pretty-project-sidebar-wrapper">
            {props.setSidebarVisible &&
                <div
                    className="welcome-sidebar-close"
                    onClick={() => props.setSidebarVisible(false)}
                >
                    <Icon
                        className="control-icon"
                        name="close"
                    />
                </div>
            }
            <div className="header-div">
                <div className="side-title" title={props.lowFlowDiversion.name}>
                    {props.lowFlowDiversion.name.length > 49 ? props.lowFlowDiversion.name.slice(0, 49) + "..." : props.lowFlowDiversion.name}
                </div>
            </div>

            <Menu pointing secondary className="menu-items-ppp">
                <Menu.Item
                    size="large"
                    name="overview"
                    active={activeItem === "overview"}
                    onClick={() => setActiveItem("overview")}
                    content="Overview"
                />

                <Menu.Item
                    size="large"
                    name="capture"
                    active={activeItem === "capture"}
                    onClick={() => setActiveItem("capture")}
                    content="Capture"
                />
            </Menu>

            <div className="body-div">
                <div className="overview-tab-section">

                    {/* // Overview Tap */}

                    {activeItem === "overview" && <>
                        <div className="wave-div">
                            <img src={Wave} alt="wave" className="wave" />
                        </div>
                        <div className="cards-container">
                            <div className="custom-cards">
                                <div className="cards-header-div">
                                    <div className="header">Key Information</div>
                                </div>
                                <div className="cards-content">
                                    <div className="cards-content-item">
                                        <span className="bold ">Jurisdiction:</span>
                                        <div className="key-info-text">{props.lookups.getJurisdictionById(1089)}
                                    </div>
                                </div>
                                <div className="cards-content-item">
                                    <span className="bold">Project Type:</span>
                                    <div className="key-info-text">
                                        {/* Low Flow Diversion (LFD) */}
                                        {props.lookups?.lutLowFlowDiversionType.find(x => x.id === props.lowFlowDiversion.lowFlowDiversionTypeId)?.name}
                                    </div>
                                </div>
                                    <div className="cards-content-item">
                                        <span className="bold">Date Completed:</span>
                                        <div className="key-info-text">
                                            <span>{dateDMY(props.lowFlowDiversion.completionDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="spacer3"></div>

                            <div className="custom-cards">
                                <div className="cards-header-div">
                                    <div className="header">Project Attributes
                                    </div>
                                </div>
                                <div className="cards-content">
                                    <div className="cards-content-item">
                                        <span className="bold">Area Managed:</span>
                                        <div className="key-info-text">{customFormat(props.lowFlowDiversion.drainageAreaAc) + " acres"}</div>
                                    </div>
                                    <div className="cards-content-item">
                                        <span className="bold">Daily Flow:</span>
                                        <div className="key-info-text">
                                            {`${customFormat(props.lowFlowDiversion.dailyFlowGal as number)} gallons`}
                                        </div>
                                    </div>
                                    <div className="cards-content-item">
                                        <span className="bold">Max Flow:</span>
                                        <div className="key-info-text">
                                            {`${customFormat(props.lowFlowDiversion.maxFlowGpm as number)} gallons per minute`}
                                        </div>
                                    </div>
                                    <div className="cards-content-item">
                                        <span className="bold">Operating Hours:</span>
                                        <div className="key-info-text">
                                            {props.lowFlowDiversion.is24Hour ? "24 hours"
                                                :
                                                `${convertToAmPm(props.lowFlowDiversion.startHour)} - ${convertToAmPm(props.lowFlowDiversion.endHour)}`
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="wave-div">
                            <img src={Wave2} alt="wave" className="wave" />
                        </div>
                    </>}

                    {/* // Capture Tap */}

                    {activeItem === "capture" && <>
                        <div className="capture-tab-section">
                            <div className="wave-div">
                                <img src={Wave} alt="wave" className="wave" />
                            </div>
                            <div className="bubble-div">

                                <div className="bubble-inner-div">
                                    <div className="water-container">
                                        {/* TOTAL CAPTURE BUBBLE */}
                                        <WaterGauge
                                            value={75}
                                            size={50}
                                            color="rgb(23, 139, 202)"
                                            displayValue={lfdBenefits}
                                            offsetX={0}
                                            offsetY={4}
                                            numberSize={1} //size of number inside bubble
                                            spacing="bubble-space"
                                            metricColor="#fff" //color of metric label text
                                            metricSize="metric-small" //size of metric label text
                                            metric={"ac-ft"} //metric units
                                        />
                                    </div>

                                    <div className="text-wrapper">
                                        <div className="div-1">Total Capture</div>
                                        <div className="div-2">
                                            Total stormwater captured by this project since construction
                                        </div>
                                    </div>
                                </div>

                                <div className="bubble-inner-div">
                                    <div className="text-wrapper">
                                        <div className="div-1">Recent Capture</div>
                                        <div className="div-2">
                                            <span>Stormwater captured by this project during the recent dry event ({new Date(latestCapture.eventStart).toDateString()} - {new Date(latestCapture.eventEnd).toDateString()})</span>
                                        </div>
                                    </div>
                                    <div className="water-container">
                                        {/* RECENT CAPTURE BUBBLE */}
                                        <WaterGauge
                                            value={75}
                                            size={50}
                                            color="rgb(23, 139, 202)"
                                            displayValue={latestCapture.amount}
                                            offsetX={0}
                                            offsetY={4}
                                            numberSize={1}
                                            spacing="bubble-space"
                                            metricColor="#fff" //color of metric label text
                                            metricSize="metric-small" //size of metric label text
                                            metric={"ac-ft"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="wave-div">
                                <img src={Wave2} alt="wave" className="wave" />
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );
};
