import React, { useState, useMemo } from "react";
import { Icon, Menu } from "semantic-ui-react";

import LookupFactory from "../../../lookups/LookupFactory";
import { WaterGauge } from "../../waterGauge/WaterGauge";
import { customFormat } from "../../../core/NumberFormat";
import { PrettyProjectPageSection } from "./PrettyProjectPage";
import { SpreadingGround, SpreadingGroundBasinTypeId } from "../../../models/SpreadingGround";
import { CaptureBenefit } from "../../../models/CaptureBenefit";

import Wave from "../../../images/wave3.svg";
import Wave2 from "../../../images/upsideDownWave5.svg";

import "./PrettyProjectPage.scss";

export type PrettyProjectPageSpreadingGroundsProps = {
    lookups: LookupFactory;
    setSidebarVisible?: Function;
    spreadingGroundsData: SpreadingGround;
    spreadingGroundsCaptureData: CaptureBenefit[];
}

const PrettyProjectPageSpreadingGrounds: React.FC<PrettyProjectPageSpreadingGroundsProps> = (props: PrettyProjectPageSpreadingGroundsProps) => {
    const [activeItem, setActiveItem] = useState<PrettyProjectPageSection>("overview");

    const captureBenefitsTotal: number = useMemo((): number => {
        return sumTotalCapture(props.spreadingGroundsCaptureData);
    }, [props.spreadingGroundsCaptureData]);

    return (
        <div className="pretty-project-sidebar-wrapper">
            {props.setSidebarVisible &&
                <div
                    className="welcome-sidebar-close"
                    onClick={() => props.setSidebarVisible(false)}
                >
                    <Icon
                        className="control-icon"
                        name="close"
                    />
                </div>
            }
            <div className="header-div">
                <div className="side-title" title={props.spreadingGroundsData.name}>
                    {props.spreadingGroundsData.name.length > 49 ? props.spreadingGroundsData.name.slice(0, 49) + "..." : props.spreadingGroundsData.name}
                </div>
            </div>

            <Menu pointing secondary className="menu-items-ppp">
                <Menu.Item
                    size="large"
                    name="overview"
                    active={activeItem === "overview"}
                    onClick={() => setActiveItem("overview")}
                    content="Overview"
                />

                <Menu.Item
                    size="large"
                    name="capture"
                    active={activeItem === "capture"}
                    onClick={() => setActiveItem("capture")}
                    content="Capture"
                />
            </Menu>

            <div className="body-div">
                <div className="overview-tab-section">

                    {/* // Overview Tap */}

                    {activeItem === "overview" && <>
                        <div className="wave-div">
                            <img src={Wave} alt="wave" className="wave" />
                        </div>
                        <div className="cards-container">
                            <div className="custom-cards">
                                <div className="cards-header-div">
                                    <div className="header">Key Information</div>
                                </div>
                                <div className="cards-content">
                                    <div className="cards-content-item">
                                        <span className="bold">Basin Type:</span>
                                        <div className="key-info-text">
                                            {SpreadingGroundBasinTypeId[props.spreadingGroundsData.basinTypeId] || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="cards-content-item">
                                        <span className="bold">Total Area:</span>
                                        <div className="key-info-text">
                                            <span>{props.spreadingGroundsData.grossAreaAc} acres </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="spacer3"></div>

                            <div className="custom-cards">
                                <div className="cards-header-div">
                                    <div className="header">Project Attributes
                                    </div>
                                </div>
                                <div className="cards-content">
                                    <div className="cards-content-item">
                                        <span className="bold">Intake Capacity:</span>
                                        <div className="key-info-text-sg">
                                            {customFormat(props.spreadingGroundsData.intakeCapacityCfs as number)} cfs
                                        </div>
                                    </div>
                                    <div className="cards-content-item">
                                        <span className="bold">Storage Capacity:</span>
                                        <div className="key-info-text-sg">
                                            {customFormat(props.spreadingGroundsData.storageCapacityAcft as number)} cfs
                                        </div>
                                    </div>
                                    <div className="cards-content-item">
                                        <span className="bold">Percolation Rate:</span>
                                        <div className="key-info-text-sg">
                                            {customFormat(props.spreadingGroundsData.percolationCapacityCfs as number)} cfs
                                        </div>
                                    </div>
                                    <div className="cards-content-item">
                                        <span className="bold">Primary source of<br></br>capture stormwater:</span>
                                        <div className="key-info-text-sg">
                                            {props.spreadingGroundsData.waterSourceDescription}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="wave-div">
                            <img src={Wave2} alt="wave" className="wave" />
                        </div>
                    </>}

                    {/* // Capture Tap */}

                    {activeItem === "capture" && <>
                        <div className="capture-tab-section">
                            <div className="wave-div">
                                <img src={Wave} alt="wave" className="wave" />
                            </div>
                            <div className="bubble-div">

                                <div className="bubble-inner-div">
                                    <div className="water-container">
                                        {/* TOTAL CAPTURE BUBBLE */}
                                        <WaterGauge
                                            value={75}
                                            size={50}
                                            color="rgb(23, 139, 202)"
                                            displayValue={captureBenefitsTotal}
                                            offsetX={0}
                                            offsetY={4}
                                            numberSize={1} //size of number inside bubble
                                            spacing="bubble-space"
                                            metricColor="#fff" //color of metric label text
                                            metricSize="metric-small" //size of metric label text
                                            metric={"ac-ft"} //metric units
                                        />
                                    </div>

                                    <div className="text-wrapper">
                                        <div className="div-1">Total Capture</div>
                                        <div className="div-2">
                                            Total stormwater captured by this project since construction
                                        </div>
                                    </div>
                                </div>

                                <div className="bubble-inner-div">
                                    <div className="text-wrapper">
                                        <div className="div-1">Recent Capture</div>
                                        <div className="div-2">
                                            <span>Stormwater captured by this project during the recent reporting period</span>
                                        </div>
                                    </div>
                                    <div className="water-container">
                                        {/* RECENT CAPTURE BUBBLE */}
                                        <WaterGauge
                                            value={75}
                                            size={50}
                                            color="rgb(23, 139, 202)"
                                            displayValue={props.spreadingGroundsCaptureData[props.spreadingGroundsCaptureData.length - 1]?.amount as number}
                                            offsetX={0}
                                            offsetY={4}
                                            numberSize={1}
                                            spacing="bubble-space"
                                            metricColor="#fff" //color of metric label text
                                            metricSize="metric-small" //size of metric label text
                                            metric={"ac-ft"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="wave-div">
                                <img src={Wave2} alt="wave" className="wave" />
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div >
    );
};

export default PrettyProjectPageSpreadingGrounds;

function sumTotalCapture(projectRecords: CaptureBenefit[]): number {
    let totalDataCount = 0;
    for (const record of projectRecords) {
        totalDataCount += (record?.amount as number) || 0;
    }
    return totalDataCount;
};